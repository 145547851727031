
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-trainer",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      employee: {
        name: "",
        designation: "",
        dob: "",
        nid: "",
        passport: "",
        driving_license: "",
        mobile: "",
        email: "",
        gender: "",
        religion: "",
        present_address: "",
        present_district: "",
        permanent_address: "",
        permanent_district: "",
      },
      user: {
        password: "",
        password_confirmation: "",
        active_status: "1",
      },
      obj: {
        entity_type_id: "",
        id: "",
      },
      entity: {
        entity_type_id: "",
        entity_type_role_id: "",
        user_id: "",
        entity_info_id: "",
        institute_info_id: "",
      },
      entityInstitute: {
        entityId: "",
        trainingInstituteId: "",
      },

      entityRoles: [],
      entityInfos: [],
      institutes: [],
      empDistricts: [],
      loading: false,
    };
  },
  async created() {
    await this.getDistrictForEmp();
    await this.getEntityInfos();
    this.emitter.on("assign_data", async (data) => {
      this.entity.user_id = data.employee.user_id;
    });
  },
  methods: {
    async formSubmit() {
      //let data = new FormData();
      let employee = {};
      Object.keys(this.employee).forEach((key) => {
        if (this.employee[key] != "") employee[key] = this.employee[key];
      });
      let user = {};
      Object.keys(this.user).forEach((key) => {
        if (this.user[key] != "") user[key] = this.user[key];
      });

      let data = {
        employee: employee,
        user: user,
      };
      console.log(data);
      this.loading = true;
      await ApiService.post("associatioin/new", data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              text: response.data.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.employee = {
                name: "",
                designation: "",
                dob: "",
                nid: "",
                passport: "",
                driving_license: "",
                mobile: "",
                email: "",
                gender: "",
                religion: "",
                present_address: "",
                present_district: "",
                permanent_address: "",
                permanent_district: "",
              };
              this.user = {
                password: "",
                password_confirmation: "",
                active_status: "1",
              };
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },

    async getDistrictForEmp() {
      await ApiService.get("geo/districts")
        .then((response) => {
          this.empDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      await ApiService.get(
        "entity/trainingInstitutes/" + this.entityInstitute.entityId
      )
        .then((response) => {
          this.institutes = response.data;
          console.log("ok" + response);
          this.obj = {
            entity_type_id: "",
            id: "",
          };
          this.entity.entity_type_role_id = "";
          this.entityRoles = [];
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const TrainerSchema = Yup.object().shape({
      employeeEmail: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label("Gender"),
      mobile: Yup.number().required().min(11).label("Mobile"),
      dob: Yup.string().required().label("Date of Birth"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      TrainerSchema,
    };
  },
});
